var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('h3',{staticClass:"d-block"},[_vm._v(" Citations ")]),_c('div',{staticClass:"ml-3"},[_c('div',[_vm._v(" From References: "),(_vm.article.counts && _vm.article.counts.cited)?_c('RouterLink',{attrs:{"to":{
            name:'PublicationsSearch',
            query: {
              query: 'cp:' + _vm.article.paperId,
            }
          }}},[_vm._v(" "+_vm._s(_vm.article.counts.cited.toLocaleString())+" ")]):_c('span',[_vm._v("0")])],1),_c('div',[_c('span',[_vm._v("From Reviews:")]),(_vm.article.counts && _vm.article.counts.reviewCited)?_c('span',[_c('RouterLink',{attrs:{"to":{
              name:'PublicationsSearch',
              query: {
                query: _vm.fromReviewsQuery,
              }
            }}},[_vm._v(" "+_vm._s(_vm.article.counts.reviewCited.toLocaleString()))])],1):_c('span',[_vm._v(" 0")])])]),(_vm.article.details && _vm.article.details.annote2 && _vm.article.details.annote2.length > 0)?_c('div',{staticClass:"mt-3",domProps:{"innerHTML":_vm._s(_vm.article.details.annote2[0])}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }